import React from 'react'
import { Link } from 'react-router-dom'

function Pricing() {
  return (
    <>
    <div className="page-section">
                <div className="container">
                    <div className="text-center wow fadeInUp">
                    <h2 className="title-section">Pricing Plan</h2>
                    <div className="divider mx-auto"></div>
                    </div>
            
                    <div className="row justify-content-center">
                    <div className="col-12 col-lg-3 py-3 wow fadeInLeft">
                        <div className="card-pricing starter bg-success">
                        <div className="header">
                            <div className="price-icon"><span className="mai-shield-checkmark text-white"></span></div>
                            <div className="price-title text-white">Starter</div>
                        </div>
                        <div className="body py-3">
                            <div className="price-tag">
                            <span className="currency text-white">&#8377;</span>
                            <h2 className="display-4 text-white">1999</h2>
                            <span className="period text-white">/monthly</span>
                            </div>
                            <div className="price-info ">
                            <p className='text-white'>Billing Services</p>
                            </div>
                        </div>
                        <div className="footer">
                            <a href="#contact" className="btn btn-outline rounded-pill">Choose Plan</a>
                        </div>
                        </div>
                    </div>
            
            
                    <div className="col-12 col-lg-3 py-3 wow fadeInRight">
                        <div className="card-pricing basic bg-warning">
                        <div className="header">
                            <div className="price-icon"><span className="mai-rocket-outline text-white" ></span></div>
                            <div className="price-title text-white">Basic</div>
                        </div>
                        <div className="body py-3">
                            <div className="price-tag">
                            <span className="currency text-white">&#8377;</span>
                            <h2 className="display-4 text-white">2999</h2>
                            <span className="period text-white">/monthly</span>
                            </div>
                            <div className="price-info">
                            <p className='text-white'>Inventory & Billing Services</p>
                            </div>
                        </div>
                        <div className="footer">
                            <a href="#contact" className="btn btn-outline rounded-pill">Choose Plan</a>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 py-3 wow fadeInRight">
                        <div className="card-pricing standard bg-danger">
                        <div className="header">
                            <div className="price-icon"><span className="mai-star text-white"></span></div>
                            <div className="price-title text-white">Standard</div>
                        </div>
                        <div className="body py-3">
                            <div className="price-tag">
                            <span className="currency text-white">&#8377;</span>
                            <h2 className="display-4 text-white">3999</h2>
                            <span className="period text-white">/monthly</span>
                            </div>
                            <div className="price-info">
                            <p className='text-white'>E-Commerce Services</p>
                            </div>
                        </div>
                        <div className="footer">
                            <a href="#contact" className="btn btn-outline rounded-pill">Choose Plan</a>
                        </div>
                        </div>
                    </div>
                    
                    <div className="col-12 col-lg-3 py-3 wow fadeInUp">
                        <div className="card-pricing active">
                        <div className="header">
                            <div className="price-labled">Best</div>
                            <div className="price-icon"><span className="mai-bonfire-outline text-white"></span></div>
                            <div className="price-title">Premium</div>
                        </div>
                        <div className="body py-3">
                            <div className="price-tag">
                            <span className="currency">&#8377;</span>
                            <h2 className="display-4">4999</h2>
                            <span className="period">/monthly</span>
                            </div>
                            <div className="price-info">
                            <p className='text-white'>Inventory, Billing & E-Commerce Services</p>
                            </div>
                        </div>
                        <div className="footer">
                            <a href="#contact" className="btn btn-outline rounded-pill">Choose Plan</a>
                        </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
    </>
  )
}

export default Pricing;