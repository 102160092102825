import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

function Navbar(){
  return (
    <nav className="navbar navbar-expand-lg bg-light navbar-light fixed-top">
                <div className="container">
                    <Link to="/" className="navbar-brand"><span className="text-dark">OffineTo</span><span className="text-primary">Online</span></Link>

                    <button className="navbar-toggler" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" ></span>
                    </button>

                    <div className="navbar-collapse collapse" id="navbarContent">
                    <ul className="navbar-nav ml-lg-4 pt-3 pt-lg-0">
                        <li className="nav-item active">
                        <Link to='/' className="nav-link ">Home</Link>
                        </li>
                        <li className="nav-item">
                        <HashLink className="nav-link text-dark" to="/#about"> About</HashLink>
                        </li>
                        <li className="nav-item">
                        <Link to="/services" className="nav-link text-dark">Services</Link>
                        </li> 
                        <li className="nav-item">
                        <HashLink to="#pricing" className="nav-link text-dark">Pricing</HashLink>
                        </li>
                        <li className="nav-item">
                        <HashLink to="#clients" className="nav-link text-dark">Clients</HashLink>
                        </li>
                        <li className="nav-item">
                        <HashLink to="#contact" className="nav-link text-dark">Contact</HashLink>
                        </li>
                    </ul>

                    <div className="ml-auto">
                        <HashLink to="#pricing" className="btn btn-outline rounded-pill">Get a Quote</HashLink>
                    </div>
                    </div>
                </div>
                </nav>
  )
  }
export default Navbar;