import React from 'react'
import { Link } from 'react-router-dom'

function Clients() {
  return (
    <>
    <div className="page-section client-section">
        <div className="container-fluid">
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 justify-content-center">
                <h3 className='text-center'>Coming Soon</h3>
            {/* <div className="item wow zoomIn">
                <img src=" assets/img/clients/airbnb.png" alt=""/>
            </div>
            <div className="item wow zoomIn">
                <img src=" assets/img/clients/google.png" alt=""/>
            </div>
            <div className="item wow zoomIn">
                <img src=" assets/img/clients/stripe.png" alt=""/>
            </div>
            <div className="item wow zoomIn">
                <img src=" assets/img/clients/paypal.png" alt=""/>
            </div>
            <div className="item wow zoomIn">
                <img src=" assets/img/clients/mailchimp.png" alt=""/>
            </div> */}
            </div>
        </div> 
    </div>
    </>
  )
}

export default Clients;